
import API from '@/api/manager/feedBack/replay'
import { encryptCode,decryptCode } from "@/utils/function";
import {request_sign} from '@/utils/const'
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
export default {
  name: "replay",
  inject: ['reload'],
  components: {
    VueUeditorWrap
  },
  data() {
    return {
        // 富文本配置
        myConfig: {
            // 初始容器宽度
            initialFrameWidth: "100%",
            // 初始容器高度
            initialFrameHeight: 100,
            // 你的UEditor资源存放的路径,相对于打包后的index.html
            UEDITOR_HOME_URL: "./Editor/",
            // 编辑器不自动被内容撑高
            // autoHeightEnabled: false,
            // 如果需要上传功能,找后端小伙伴要服务器接口地址
            serverUrl: editorUploadUrl,
            // 关闭自动保存
            enableAutoSave: false,
            // 自定义工具栏，需要额外选项可以参考ueditor.config.js
            toolbars: [
              [
                "inserttable",//表格
                "source", //源代码
                "bold", //加粗
                "italic", //斜体
                "underline", //下划线
                "strikethrough", //删除线
                "fontborder", //字符边框
                "blockquote", //引用
                "selectall", //全选
                "horizontal", //分隔线
                "removeformat", //清除格式
                "unlink", //取消链接
                "deletecaption", //删除表格标题
                "inserttitle", //插入标题
                "cleardoc", //清空文档
                "insertcode", //代码语言
                "fontfamily", //字体
                "fontsize", //字号
                "insertimage", //多图上传
                "link", //超链接
                "emotion", //表情
                "spechars", //特殊字符
                "searchreplace", //查询替换
                "insertvideo", //视频
                "justifyleft", //居左对齐
                "justifyright", //居右对齐
                "justifycenter", //居中对齐
                "forecolor", //字体颜色
                "insertorderedlist", //有序列表
                "insertunorderedlist", //无序列表
                "imageleft", //左浮动
                "imageright", //右浮动
                "attachment", //附件
                "imagecenter", //居中
                "lineheight", //行间距
              ],
            ],
        },
        // 加入问题库
        signSend:false,
        // 关键词
        keywords:'',
        // 回复内容
        content:'',
        // 预览图片信息
        previewquestionImg:[],
        // 反馈信息id
        spit_id:'',
        // 反馈信息详情
        info:{},
        // 聊天列表
        list:[],
        // 虚拟实验名称
        expname:''
    };
  },
  /**
   * @name: 页面创建成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  created() {
    let self = this;
    if(this.$router.currentRoute.query.params)
    {
        // let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
        // self.spit_id = params?.id
        // self.expname = params?.expname
        self.expname = sessionStorage.getItem('expname');
        self.spit_id = sessionStorage.getItem('spit_id');
    }
    self.getSpitInfo()
  },
  methods: {
    /**
     * @name: 获取editor示例
     * @author: LXY
     * @date: 2022-12-2 10:13:50
     */
    ready(editorInstance) {
        var self = this;
        editorInstance.focus();
        var str = "exam_paper_" + self.bank_id;
        // 添加自定义参数
        editorInstance.execCommand("serverparam", function(editor) {
            return {
                articleid: str //设置富文本编辑器请求时，携带的额外参数
            };
        });
    },
    /**
     * @name: 获取意见反馈信息
     * @author: LXY
     * @date:  2022-12-2 10:13:50
     */
    getSpitInfo()
    {
      let self = this;
      let str = encryptCode({
        spit_id:self.spit_id,
      })
      let data = {sign:request_sign,params:str}
      API.getSpitInfo(data)
          .then((res)=>{
            if (res.code > 0) 
            {
              self.info = res.info;
              res.list?.forEach((item)=>{
                item.isshow = item.isshow === "0"?false:true
              })
              self.list = res.list
              self.previewquestionImg.push(self.info.pic)
            }
            else
            {
                self.$Tips({
                    // 消息提示内容
                    message: result.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
          })
    },
    /**
     * @name: 回复消息
     * @author: LXY
     * @date:  2022-12-2 10:13:50
     */
    sendReplay()
    {
      let self = this;
      if(self.signSend && !self.keywords)
      {
        return self.$Tips({
          // 消息提示内容
          message: '请输入关键词',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      if(self.content == '')
      {
        return self.$Tips({
          // 消息提示内容
          message: '请输入回复内容',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      let str = encryptCode({
        spit_id:self.spit_id,
        signSend:self.signSend,
        keywords:self.keywords,
        content:self.content
      })
      // console.log({
      //   spit_id:self.spit_id,
      //   signSend:self.signSend,
      //   keywords:self.keywords,
      //   content:self.content
      // });
      let data = {sign:request_sign,params:str}
      API.replySpitToStudent(data)
          .then((res)=>{
            if (res.code > 0) 
            {
              self.content = '';
              self.signSend = false;
              self.keywords = '';
              self.getSpitInfo();
              self.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })
            }
            else
            {
                self.$Tips({
                    // 消息提示内容
                    message: res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
          })
    },
    /**
     * @name: 修改反馈是否显示
     * @author: camellia
     * @date:  2022-12-2 10:13:50
     */
    updateSpitShow(item)
    {
      console.log(item);
      let self = this;
      // return;
      let str = encryptCode({
        spit_id:item.id,
        spit_sign:item.is_spit,
        isshow:item.isshow === false?'0':'1'
      })
      console.log({
        spit_id:item.id,
        spit_sign:false,
        isshow:item.isshow === false?'0':'1'
      });
      let data = {sign:request_sign,params:str}
      API.updateSpitShow(data)
          .then((res)=>{
            if (res.code > 0) 
            {
              // self.$Tips({
              //   // 消息提示内容
              //   message: res.msg,
              //   // 消息提示类型（success-成功,warning-警告/失败）
              //   messageType: 'success',
              //   displayTime: 1500
            // })
            }
            else
            {
                self.$Tips({
                    // 消息提示内容
                    message: res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
          })
    }
  }
};
