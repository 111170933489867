import { request } from "@/request/http";

/**
 * @name: 意见反馈回复接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  // 获取意见反馈信息
  getSpitInfo: (data) => {
    return request("POST", `/index.php/adminSpit/getSpitInfo`, data);
  },
  // 给学生回复反馈信息
  replySpitToStudent: (data) => {
    return request("POST", `/index.php/adminSpit/replySpitToStudent`, data);
  },
  // 修改反馈是否显示
  updateSpitShow: (data) => {
    return request("POST", `/index.php/adminSpit/updateSpitShow`, data);
  },
}