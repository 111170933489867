<template>
    <div  class="replay">
        <el-card>

    <!-- 回复框 -->
     <div class="opt">
        <el-input placeholder="请输入关键词" v-model="keywords"></el-input>
        <el-checkbox v-model="signSend" label="加入问题库" border></el-checkbox>
        <el-button class="themeButton" @click="sendReplay">发送</el-button>
     </div>
     <div class="ueditor">
        <vue-ueditor-wrap
            v-model="content"
            :config="myConfig"
            @ready="ready"
        ></vue-ueditor-wrap>
     </div>
     <div class="setDetail">
        <div class="detail">
            <div class="info">
            <div>
                {{expname}}
            </div>
            <div class="step">
                <div>CID：{{info.CID}}</div> 
                <div class="text">STEP：{{info.step}}</div>
            </div> 
            </div>
            <div class="question">
            <div>
                <el-image 
                v-show="info.pic"
                :src="info.pic" 
                :preview-src-list="previewquestionImg">
                </el-image>
            </div>
            <div class="info_box">
                <div class="info_item">
                姓名：{{info.name}}
                </div>
                <div class="info_item">
                学校：{{info.schoolName}}
                </div>
                <div class="info_item">
                电话：{{info.tel}}
                </div>
                <br>
                <div class="info_item">
                版本号：{{info.clientversion}}
                </div>
                <div class="info_item">
                设备型号：{{info.phonetype}}
                </div>
                <div class="info_item">
                提交时间：{{info.subtime}}
                </div>
                <div class="record">
                <div>{{info.spit}}</div> 
                <div>{{info.lastSpit}}</div> 
                
            </div>
            </div>
            
            </div>
        </div>
        <div class="recordBox">
            <!-- 聊天记录 -->
            <div :class="['record','clearFix']" v-for="item in list">
                <div :class="['avator',item.class=='left'?'row':'rowreverse' ]">
                    <div>
                        <img :src="item.wx_img" alt="">
                    </div>
                    <div class="record_text" v-html="item.content"></div>
                    <div class="record_switch">
                        <el-switch 
                            active-color="#0D5570"
                            inactive-color="#CCCCCC" 
                            v-model="item.isshow" 
                            @change="updateSpitShow(item)">
                        </el-switch>
                    </div>
                </div>
            </div>
        </div>
     </div>

    </el-card>
    </div>
  </template>
<style lang="scss">

.replay {
    // .el-select{
        .el-input__inner{
            height: 2.0833vw;
            font-size: 0.833vw;
        // }
    }
}
.replay .el-checkbox__label{
  font-size: 0.8333vw;
}
.replay .el-checkbox__input.is-checked .el-checkbox__inner,.replay .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0D5570;
    border-color: #0D5570;
}
.replay .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #0D5570;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/manager/feedBack/replay.scss";
</style>

<script>
import replay from "@/assets/js/manager/feedBack/replay.js";
export default {
...replay
};
</script> 